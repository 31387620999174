;(function() {
/* @ngInject */
function ModalFounderChange($uibModal, $rootScope) {
  let cities, countries, companiesTypes, founder, isSelf, personFromOldOrder;

  return function init(cities_, countries_, companiesTypes_, founder_, isSelf_, personFromOldOrder_) {
    cities = cities_;
    countries = countries_;
    founder = founder_;
    companiesTypes = companiesTypes_;
    isSelf = isSelf_;
    personFromOldOrder = personFromOldOrder_;

    return { add };
  };

  function open(options = {}) {
    return $uibModal.open(angular.extend({
      templateUrl: "/app/shared/modal-founder-change/modal-founder-change.html",
      controller: "ModalFounderChangeController",
      controllerAs: "vm",
      bindToController: true,
      size: "lg",
      backdrop: "static",
      resolve: {
        cities: () => cities,
        countries: () => countries,
        founder: () => founder,
        companiesTypes: () => companiesTypes,
        isSelf: () => isSelf,
        personFromOldOrder: () => personFromOldOrder
      }
    }), options);
  }

  function add(scope = {}) {
    return open({ scope: angular.extend($rootScope.$new(), scope) })
  }

}

/* @ngInject */
function ModalFounderChangeController($uibModalInstance, cities, countries, companiesTypes, isSelf, personFromOldOrder, founder, $window, $interval, $scope, $filter, Helpers) {
  let vm = this;

  vm.genders = ["male", "female"];

  vm.companiesTypes = companiesTypes;
  vm.isSelf = isSelf;
  vm.focusSurname = false;
  vm.focusName = false;
  vm.personFromOldOrder = personFromOldOrder;

  let companySuffix = vm.companiesTypes.split(";");

  vm.oldName = founder.name;
  vm.oldSurname = founder.surname;

  vm.isChange = true;

  vm.founder = angular.copy(founder);

  if(vm.founder.executor && !vm.founder.typeChange)
    vm.founder.typeChange = 2;

  if(!founder.idNumber) {
    vm.founder.idNumber = {};
  }

  if(!founder.dateOfBirth) {
    vm.founder.dateOfBirth = {};
  }

  vm.cities = cities;
  vm.countries = countries;

  let now = new Date();
  vm.founder.date = now;

  if(!vm.founder.address) {
    vm.founder.address = {
      country: ""
    };
    vm.founder.address.country = vm.countries[0]._2;
  }

  if(!vm.founder.address.country)
    vm.founder.address.country = vm.countries[0]._2;

  if(companySuffix.filter(n => vm.founder.fullName.includes(n)).length > 0)
    vm.founder.type = 'po';
  else vm.founder.type = 'fo';

  let minDate = new Date();

  vm.days = createArray(1,31, false);
  vm.months = createArray(1,12, false);
  vm.years = createArray(1900, minDate.getFullYear() - 18, true);

  vm.onResetCountry = onResetCountry;
  vm.onElementFocus = Helpers.onElementFocus;
  vm.onElementBlur = Helpers.onElementBlur;
  vm.scrollTo = Helpers.scrollInModalTo;
  vm.getFullName = Helpers.getFullName;
  vm.dateToString = Helpers.dateToString;
  vm.onZipCodeChange = onZipCodeChange;
  vm.onClick = onClick;
  vm.onIdNumberChange = onIdNumberChange;
  vm.setOldData = setOldData;

  function createArray(start, end, reverse) {
    let array = [];
    for(var i = start; i <= end; i++) {
      array.push(i);
    }
    if(reverse)
      return array.reverse();
    else return array;
  }

  function setOldData(event) {
    if(event.target.checked) {
      vm.founder.gender = vm.personFromOldOrder.gender;
      vm.founder.dateOfBirth = vm.personFromOldOrder.dateOfBirth;
      vm.founder.idNumber = vm.personFromOldOrder.idNumber;
    } else {
      vm.founder.gender = "";
      vm.founder.dateOfBirth = {};
      vm.founder.idNumber = {};
    }
  }

  function onZipCodeChange($item) {
    vm.founder.address.zipCode = $item && $item._3;
  }

  function onResetCountry() {
    vm.founder.address.country = "";
    $interval(function() {
      let element = $window.document.getElementById("address.country");
      if(element)
        element.focus();
    }, 100, 1);
  }

  function onClick(item, elem) {
    if(elem === "day") {
      vm.founder.dateOfBirth.day = item;
      vm.mouseoverDay = false;
    } else if(elem === "month") {
      vm.founder.dateOfBirth.month = item;
      vm.mouseoverMonth = false;
    } else if(elem === "year") {
      vm.founder.dateOfBirth.year = item;
      vm.mouseoverYear = false;
    }

    onIdNumberChange();
  }

  function onIdNumberChange() {
    if(vm.founder) {
      if (vm.founder.gender) {
        vm.founder.idNumber.prefix = Helpers.calculateIdNumber(vm.founder.dateOfBirth, vm.founder.gender);
      } else {
        vm.onElementFocus("gender");
        vm.onElementBlur("gender");
        vm.scrollTo("gender");
      }
    }
  }

  vm.close = () => $uibModalInstance.dismiss("cancel");
  //vm.submit = () => $uibModalInstance.close(vm.founder);
  vm.submit = () => {
    if($scope.founder.$invalid) {
      vm.submitted=true;
    } else {
      if(!vm.founder.type)
        vm.submitted=true;
      else {
        if(vm.founder.type === 'fo') {
          vm.founder.fullName = vm.getFullName(vm.founder);
          vm.founder.ico = null;
        }
        // parseName(vm.founder.fullName);
        $uibModalInstance.close(vm.founder);
      }
    }
  };
}

angular.module("firmaren")
  .factory("ModalFounderChange", ModalFounderChange)
  .controller("ModalFounderChangeController", ModalFounderChangeController);
}());
