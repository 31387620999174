;(function() {
/* @ngInject */
function KeywordsAreaController($compile, $scope) {
    let vm = this;

    vm.processKey = processKey;
    vm.remove = remove;
    vm.setLastWord = setLastWord;
    vm.getCountOfChars = getCountOfChars;

    vm.editableContent = "";
    vm.index = 0;

    $scope.$on('clear-keywords', function (event, args) {
        clear();
    });

    function clear() {
        vm.form.$touched = false;

        let parent = document.getElementById('keywordsId');
        let children = parent.querySelectorAll('.highlight-block');
        children.forEach(function(child) {
            parent.removeChild(child);
        });

        vm.model = {
            values: [],
            lastValue: ""
        }
    }

    function setLastWord() {
        if(vm.model.lastValue.length > 0) {
            var inputElement = document.getElementById('keywordsId');
            var fakeEvent = {
                keyCode: 188,
                target: inputElement
            };
            vm.processKey(fakeEvent, true);
        }
    }

    function remove(index) {
        let element = document.getElementsByClassName('index' + index)[0];
        let value = element.innerText;
        element.parentNode.removeChild(element);
        vm.model.values = vm.model.values.filter(v => {
            return v !== value;
        });
    }

    function getCountOfChars() {
        let count = 0;
        vm.model.values.forEach(v => {
            count = count + v.length;
        });

        return count;
    }

    function processKey(event, fake) {
        if (event.keyCode === 188) { // Kód klávesy pre čiarku
            var selection = window.getSelection();

            var startIndex = 0;
            var lastSpanIndex = event.target.innerHTML.lastIndexOf("</div>");
            if(lastSpanIndex > -1) {
                startIndex = lastSpanIndex + 6;
            }

            var oldText = event.target.innerHTML.substring(0, startIndex).trim();

            var newText = event.target.innerHTML.substring(startIndex, event.target.innerHTML.length).trim();

            let novaHodnota = newText.replace(",", "");
            vm.model.values.push(novaHodnota);
            if(vm.model.lastValue === novaHodnota) {
                vm.model.lastValue = "";
            }

            // Vytvorenie nového div elementu
            var newDiv = angular.element('<div>');
            newDiv.attr('contenteditable', 'false');
            newDiv.addClass('highlight-block');
            newDiv.addClass('index' + vm.index);

            // Vytvorenie nového span elementu
            var newSpan = angular.element('<span>');
            newSpan.attr('contenteditable', 'false');
            newSpan.text(newText.replace(",", ""));

            // Vytvorenie nového img elementu
            var newImg = angular.element('<img>');
            newImg.addClass('highlight-icon');
            newImg.attr('src', '/assets/images/clear-circle.png');
            newImg.attr('ng-click', 'vm.remove(' + vm.index + ')');
            vm.index = vm.index + 1;

            // Pridanie span a img elementov do div elementu
            newDiv.append(newSpan);
            newDiv.append(newImg);

            event.target.innerHTML = '';

            let compiledOldDiv = $compile(oldText)($scope);
            let compiledDiv = $compile(newDiv)($scope);

            angular.element(document.getElementById('keywordsId')).append(compiledOldDiv);
            angular.element(document.getElementById('keywordsId')).append(compiledDiv);
            angular.element(document.getElementById('keywordsId')).append(" ");

            var newRange = document.createRange();
            newRange.selectNodeContents(event.target);
            newRange.collapse(false);
            if(!fake) {
                selection.removeAllRanges();
                selection.addRange(newRange);
            }
        } else if (event.keyCode === 8 || event.keyCode === 46) {
            var childNodes = event.target.childNodes;

            vm.model.values = [];

            for (var i = 0; i < childNodes.length; i++) {
                if (childNodes[i].nodeType === Node.ELEMENT_NODE) {
                    if(childNodes[i].textContent.length > 0)
                        vm.model.values.push(childNodes[i].textContent);
                } else {
                    if(childNodes[i].textContent.startsWith(" "))
                        vm.model.lastValue = childNodes[i].textContent.slice(1);
                    else vm.model.lastValue = childNodes[i].textContent;
                }
            }
        } else {
            let vstupnyString = event.target.innerHTML.replaceAll("<br>", "");
            var index = vstupnyString.lastIndexOf('</div>');
            if(index > -1)
                vm.model.lastValue = vstupnyString.substring(index + '</div>'.length);
            else vm.model.lastValue = vstupnyString;

            if(vm.model.lastValue.startsWith(" "))
                vm.model.lastValue = vm.model.lastValue.slice(1);
        }

    }
}

angular.module("firmaren")
    .component("keywordsArea", {
        templateUrl: "/app/shared/keywords-area/keywords-area.html",
        controller: KeywordsAreaController,
        controllerAs: "vm",
        bindings: {
            model: "=",
            form: "=",
            submitted: "="
        }
    });
}());
