;(function() {
/* @ngInject */
function BlogPostsComponentController(IndexService) {
    let server = IndexService;
    let vm = this;

    vm.posts = [];

    setTimeout(() => {
        server.getBlogPosts(vm.postIds).then(onBlogPostsLoad);
    });

    function onBlogPostsLoad(items) {
        if(items)
            vm.posts = items;
    }
}

angular.module("firmaren")
    .component("blogPosts", {
        templateUrl: "/app/shared/blog-posts/blog-posts.html",
        controller: BlogPostsComponentController,
        controllerAs: "vm",
        bindings: {
            postIds: "="
        }
    });
}());
